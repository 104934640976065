<template lang="pug">
	div.reports-widget
		loading(:active.sync="busy", :is-full-page="true")
		.card.card-custom.gutter-b(v-if="filters")
			.card-header
				.card-title
					.card-label Reports
				.card-toolbar
			.card-body
				form.form.dw-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					.form-blocks
						.block.block-dimensions
							.block-header
								h3 Dimensions
							.block-body
								.selection-list
									div(v-for="d in dimensions", :class="{selected:d._selected}")
										b-checkbox(v-model="d._selected") {{ d.label }}
								.selected-items
									div(v-for="d in selectedDimensions")
										a(href="javascript:void(0);", @click="removeDimension(d)")
											i.la.la-times
										span {{ d.label }}

						.block.block-metrics
							.block-header
								h3 Metrics
							.block-body
								.selection-list.selection-list-wide
									div.list-item(v-for="d in metrics", :class="{selected:d._selected}")
										a.toggle(v-if="d.children", href="javascript:void(0);", @click="d._expanded = !d._expanded")
											i.la(:class="{'la-caret-right': !d._expanded, 'la-caret-down': d._expanded}")
										a.toggle-all(v-if="d.children", href="javascript:void(0)", @click="toggleAllChildren(d)")
											i.la.la-check-square
										b-checkbox(v-model="d._selected", :class="{symbolic:d.symbolic}", :disabled="d.symbolic") {{ d.label }}
											=' '
											span(v-if="d.children") ({{ d.children | countSelected }})
										div(v-if="d.children", v-show="d._expanded")
											div.child(v-for="cd in d.children", :class="{selected:cd._selected}")
												b-checkbox(v-model="cd._selected") {{ cd.label }}

						.block.block-filters
							.block-header
								h3 Filters
								.block-actions
									a(href="javascript:void(0);", @click="clearFilters()") Clear
							.block-body.form-inline
								div(style="width: 100%")
									div(style="width: 100%")
										//, @input="updateDates"
										dw-date-time-picker(v-model="filters.datePicker")
										.form-group
											label Group Timeframe
											select.form-control(v-model="filters.group_timeframe", style="width: 120px;")
												option(value="none") None
												option(value="hour") Hour
												option(value="day") Day
								div(style="width: 100%")
									.form-group
										label Network
										select.form-control(v-model="filters.network_id", style="width: 120px;")
											option(:value="null") - All -
											option(v-for="n in networkOptions", :value="n.v") {{ n.t }}
									.form-group
										label Tag
										select.form-control(v-model="filters.tag_id", style="width: 120px;")
											option(:value="null") - All -
											option(v-for="n in tagOptions", :value="n.v") {{ n.t }}
									.form-group
										label P.Feed
										select.form-control(v-model="filters.publisher_feed_id", style="width: 120px;")
											option(:value="null") - All -
											option(v-for="n in publisherFeedOptions", :value="n.v") {{ n.t }}
									.form-group.fg-placement
										label Placement(s)
										multiselect(:multiple="true", :options="placementOptions", v-model="filters.placement_id",
											:limit="1", group-label="name",
											:clear-on-select="false",
											:close-on-select="false",
											:max-height="600",
											open-direction="bottom",
											track-by="v", label="t", deselect-label="", select-label="", @search-change="searchPlacement",
											:loading="placementOptionsLoading", :internal-search="false")
									select-publisher(v-model="filters.publisher_id", :network_id="filters.network_id")
									select-advertiser(v-model="filters.advertiser_id", :network_id="filters.network_id")
									.form-group.fg-offer
										label Offer(s)
										select-offer(:multiple="true", v-model="filters.offer_id", :network_id="filters.network_id", :wrapped="false")
										// group-label="name", group-values="items",
										//multiselect(:multiple="true", :options="offerOptions", v-model="filters.offer_id",
										//	:limit="1", :group-select="false",
										//	:clear-on-select="false",
										//	:close-on-select="false",
										//	:max-height="600",
										//	open-direction="bottom",
										//	track-by="id", label="name", deselect-label="", select-label="", @search-change="searchOffer",
										//	:loading="offerOptionsLoading", :internal-search="false")
									.form-group.fg-offer
										label TargetOffer(s)
										select-offer(:multiple="true", v-model="filters.target_offer_id", :network_id="filters.network_id", :wrapped="false")
										//multiselect(:multiple="true", :options="offerOptions", v-model="filters.target_offer_id",
											//track-by="id", label="name", deselect-label="", select-label="",
											//:internal-search="true")
									.form-group.fg-asset
										label Asset(s)
										multiselect(:multiple="true", :options="assetOptions", v-model="filters.asset_id",
											track-by="id", label="t", deselect-label="", select-label="",
											:internal-search="true")

										//multiselect(:multiple="true", :options="offerOptions", v-model="filters.offer_id",
										//	:limit="1", group-label="name", group-values="items", :group-select="false",
										//	track-by="id", label="name", deselect-label="", select-label="", @search-change="searchOffer",
										//	:loading="offerOptionsLoading", :internal-search="false", @open="selectOffersFilter")
								div(style="width: 100%")
									.form-group
										label Status
										select.form-control(v-model="filters.status")
											option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
									.form-group.no-label
										label.form-check-label
										b-checkbox(type="checkbox", v-model="filters.has_conversions") Has Conversions / Events
									.form-group.no-label(v-if="selectedDimensionHash.subsource")
										label.form-check-label
										b-checkbox(type="checkbox", v-model="filters.subsource_data") Expand Subsources
									.form-group
										label Groups Mode
										select.form-control(v-model="filters.groups_mode")
											option(:value="0") None
											option(:value="1") Only groups
											option(:value="2") Both
									.form-group
										label Mode
										select.form-control(v-model="filters.mode")
											option(:value="0") CPI
											option(:value="1") RTB
											option(:value="2") Both
									//.form-group.no-label
										b-checkbox(type="checkbox", v-model="filters.extended_params") Extended Params
									.form-group
										label Country
										input.form-control(type="text", v-model="filters.country")
									.form-group
										label OS
										multiselect(:multiple="true", :options="osOptions", v-model="filters.os_id",
											track-by="v", label="t", deselect-label="", select-label="")
									.form-group
										label Device Vendor
										multiselect(:multiple="true", :options="deviceVendorOptions", v-model="filters.device_vendor_id",
											track-by="v", label="t", deselect-label="", select-label="")
									.form-group
										label Device Model
										multiselect(:multiple="true", :options="deviceModelOptions", v-model="filters.device_model_id",
											track-by="v", label="t", deselect-label="", select-label="")
									.form-group
										label Browser
										multiselect(:multiple="true", :options="browserOptions", v-model="filters.browser_id",
											track-by="v", label="t", deselect-label="", select-label="")
									.form-group.no-label
										label.form-check-label
										b-checkbox(type="checkbox", v-model="showIds") Show IDs



					.row-buttons
						.presets
							button.btn.rounded-pill(v-for="p in presets", type="button", @click="setPreset(p)", :class="'btn-'+p.type")
								i.la.la-plus(v-if="p.action === 'add'")
								span {{ p.name }}
						.submit-buttons
							b-button-group
								b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
									b-dropdown-item(@click="loadData(1)")
										i.la.la-download
										| Export
									b-dropdown-item(@click="loadData(2)")
										i.la.la-download
										| Background Report
									b-dropdown-item(@click="showBackgroundReportsModal(true)")
										i.la.la-external-link
										| View Report

		.card.card-custom
			.card-header.border-0.pt-5
				h3.card-title Results
			.card-body.pt-3.pb-0
				paginate(:paginator="paginate", @update-page-size="loadData")
				.stats-table-wrapper
					table.table.table-bordered.table-compact.tbl-statistics(v-if="resultMeta")
						thead
							tr
								th(v-if="filters.group_timeframe === 'day'") Date
								th(v-if="filters.group_timeframe === 'hour'") Hour
								th.col-dim(v-for="d in resultMeta.dimensions", :class="'col-'+d.name") {{ d.label }}
								dw-th.col-metric(v-for="m in resultMeta.metrics", :key="m.name", :class="'col-'+m.name", @sort="doSort($event)", :sorter="sortBy", :field="m.name") {{ m.short_label || m.label }}

						tbody.summary
							tr(v-if="summary")
								td.c.col-dim(v-if="filters.group_timeframe === 'day' || filters.group_timeframe === 'hour'") &nbsp;
								td.col-dim(v-for="d in resultMeta.dimensions") &nbsp;
								td.c.col-metric(v-for="d in resultMeta.metrics", :class="'col-'+d.name")
									span {{ summary[d.name].toLocaleString() }}

						tbody
							tr(v-if="records.length===0")
								td(:colspan="numColumns") No matching records were found
							tr(v-for="r in records", v-bind:key="r.key", :class="getRowClass(r) + ' ' + (r._selected ? 'outline' : '')", @click="toggleSelectedRow(r)")
								td.nowrap.col-dim(v-if="filters.group_timeframe === 'day'") {{ r.group_date }}
								td.nowrap.col-dim(v-if="filters.group_timeframe === 'hour'") {{ r.group_hour }}
								template(v-for="d in resultMeta.dimensions")
									td.col-dim.col-entity(v-bind:key="r.key+'_'+(d.name)", :class="getColClass(r, d)")
										entity.no-bg(v-if="r[d.name] && r[d.name].id !== undefined", :id="r[d.name].id", :name.sync="r[d.name].name", :type="d.name",
											:mode="d.mode", :data="r[d.name]", :params="{show_id: showIds}",
											:max-width="d.width", @click="appendEntity(r,d)", @dblclick="selectEntity(r,d)")
										span(v-if="!r[d.name] || r[d.name].id === undefined") {{ r[d.field] }}

								td.c.col-metric(v-for="d in resultMeta.metrics", :class="'col-'+d.name + ' flag-'+(r[d.name] ? r[d.name].flag : '')",
									v-b-tooltip.hover, :title="r[d.name] && r[d.name].l ? r[d.name].l : ''")
									span(v-if="r[d.name]", :class="'flag-'+r[d.name].flag")
										router-link(v-if="metricsHash[d.name].link", :to="{name: metricsHash[d.name].link, query: getURLFiltersParams(r)}") {{ r[d.name].v.toLocaleString() }}
										span(v-if="!metricsHash[d.name].link") {{ r[d.name].v.toLocaleString() }}

						tfoot.summary
							tr(v-if="summary")
								th.c.col-dim(v-if="filters.group_timeframe === 'day' || filters.group_timeframe === 'hour'") &nbsp;
								th.col-dim(v-for="d in resultMeta.dimensions") &nbsp;
								th.c.col-metric(v-for="d in resultMeta.metrics", :class="'col-'+d.name")
									span {{ summary[d.name].toLocaleString() }}


				paginate(:paginator="paginate", @update-page-size="loadData")
</template>
<script>
import Vue from 'vue';
// import SelectOffersModal from '../views/modals/SelectOffersModal';
// import GeneratedReportsModal from '@/views/reports/GeneratedReportsModal';
import moment from 'moment';

export default {
	name: 'ReportsWidget',
	components: {
		// SelectOffersModal,
		// GeneratedReportsModal
	},
	props: {
		offer_id: {
			type: [String, Number],
			default(){
				return '';
			}
		},
		placement_id: {
			type: [String, Number],
			default(){
				return '';
			}
		},
		config: {
			type: Object
		}
	},
	computed: {
		selectedDimensionHash(){
			let h = {};
			this.dimensions.forEach(d => {
				if (d._selected){
					h[d.name] = d;
				}
			});
			return h;
		},
		selectedDimensions(){
			return this.dimensions.filter(d => d._selected);
		},
		selectedMetrics(){
			return this.metrics.filter(d => d._selected || d.children && d.children.filter(c => c._selected).length);
		},
		// visibleAdvertiserOptions(){
		// 	return this.advertiserOptions.filter(a => {
		// 		return this.advertiserOptionsArchive || a.status;
		// 	});
		// },
		// visiblePublisherOptions(){
		// 	return this.publisherOptions.filter(a => {
		// 		return this.publisherOptionsArchive || a.status;
		// 	});
		// },
		numColumns(){
			return this.filters.extended_params ? 22 : 21;
		},
	},
	data(){
		return {
			dimOfferIndex: -1,
			dimPlacementIndex: -1,
			busy: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filtersExpanded: false,
			sortBy: {
				field: 'revenue',
				direction: 'desc',
				sort: null,
			},
			dimensions: [],
			metrics: [],
			dimHash: {},
			metricsHash: {},
			ctitSteps: [],
			rejectReasons: [],
			blockedErrors: [],
			columns: [],
			pages: [],
			records: [],
			summary: null,
			resultMeta: null,
			networkOptions: [],
			tagOptions: [],
			publisherFeedOptions: [],
			advertiserOptionsArchive: false,
			publisherOptionsArchive: false,
			advertiserOptions: [],
			placementOptionsLoading: false,
			placementOptions: [],
			offerOptionsLoading: false,
			offerOptions: [],
			assetOptionsLoading: false,
			assetOptions: [],
			publisherOptions: [],
			osOptions: [],
			deviceModelOptions: [],
			deviceVendorOptions: [],
			offerOptionsTimeout: null,
			placementOptionsTimeout: null,
			browserOptions: [],
			userOptions: [],
			showIds: true,
			statusOptions: [
				{v: 'all', t: 'All'},
				{v: 'live', t: 'Live'},
				{v: 'paused', t: 'Paused'},
				{v: 'click_cap', t: 'Click Cap'},
				{v: 'conversion_cap', t: 'Conversion Cap'},
			],
			filters: {
				mode: 0,
				groups_mode: 0,
				has_conversions: false,
				subsource_data: false,
				group_timeframe: 'none',
				extended_params: false,
				target_offer_id: [],
				offer_id: [],
				asset_id: [],
				network_id: 1,
				tag_id: null,
				publisher_feed_id: null,
				ssp_id: null,
				placement_id: [],
				publisher_id: [],
				advertiser_id: [],
				os_id: [],
				device_vendor_id: [],
				device_model_id: [],
				browser_id: [],
				country: '',
				status: 'all',

				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: null,
						endDate: null
					},
				}
			},
			presets: []
		};
	},
	methods: {

		toggleSelectedRow(r){
			Vue.set(r, '_selected', !r._selected);
		},
		doSort(data){
			this.sortBy = data
			this.loadData();
		},
		getColClass(r, dim){
			if (dim.name === 'offer') {
				return 'col-offer-status-' + (r.offer ? r.offer.final_status : '');
			}
			if (dim.name === 'placement') {
				return 'col-offer-status-' + (r.placement ? r.placement.final_status : '');
			}
			if (dim.name === 'subsource'){
				return 'col-subsource-status-' + r.subsource.status;
			}
			return '';
		},
		getRowClass(r){
			let cls = '';
			if (this.dimHash.offer._selected) {
				cls = 'row-placement-status-' + (r.offer ? r.offer.final_status : '');
			}
			if (this.dimHash.placement._selected) {
				cls = 'row-placement-status-' + (r.placement ? r.placement.final_status : '');
			}
			return cls;
		},
		toggleViewMode(c, i){
			if (c.mode === 'mini') {
				c.mode = 'normal';
			} else {
				c.mode = 'mini';
			}
			this.$set(this.columns, i, c);
		},

		toggleAllChildren(o){
			o._toggled = !o._toggled;
			o.children.forEach(c => {
				console.log(c);
				c._selected = o._toggled;
				// Vue.set(c,'_selected', o._toggled);
			});
		},
		setPreset(p){
			if ((p.type === 'full' || p.type === 'dimensions') && p.dimensions && p.dimensions.length) {
				this.dimensions.forEach(d => {
					let selected = p.dimensions.indexOf(d.name) > -1;
					if (selected || p.action === 'set') {
						Vue.set(d, '_selected', selected);
					}
					if (d.children) {
						d.children.forEach(cd => {
							let selected = p.dimensions.indexOf(cd.name) > -1;
							if (selected || p.action === 'set') {
								Vue.set(cd, '_selected', selected);
							}
						});
					}
				});
			}
			if ((p.type === 'full' || p.type === 'metrics') && p.metrics && p.metrics.length) {
				this.metrics.forEach(d => {
					let selected = p.metrics.indexOf(d.name) > -1;
					if (selected || p.action === 'set') {
						Vue.set(d, '_selected', selected);
					}
					if (d.children) {
						d.children.forEach(cd => {
							let selected = p.metrics.indexOf(cd.name) > -1;
							if (selected || p.action === 'set') {
								Vue.set(cd, '_selected', selected);
							}
						});
					}
				});
			}
			if (p.filters){
				for (let k in p.filters){
					this.filters[k] = p.filters[k];
				}
			}
		},



		clearFilters(){
			this.filters = {
				groups_mode: 0,
				subsource_data: false,
				has_conversions: false,
				group_timeframe: 'none',
				extended_params: false,
				target_offer_id: [],
				offer_id: [],
				asset_id: [],
				placement_id: [],
				publisher_id: [],
				advertiser_id: [],
				tag_id: [],
				publisher_feed_id: [],
				os_id: [],
				device_vendor_id: [],
				device_model_id: [],
				browser_id: [],
				country: '',
				status: 'all',
				mode: 0,

				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
						endDate: moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm'),
					}
				},
			};
		},

		selectEntity(record, colIndex){
			console.log('select entity', record, colIndex);
			let col = this.columns[colIndex];
			switch (col.e) {
				case 'placement':
					//this.filters.placement_ids = '' + record[col.f];
					break;
				case 'offer':
					//this.filters.offer_ids = '' + record[col.f];
					break;
			}
		},

		appendEntity(record, dim){
			switch (dim.name) {
				case 'network':
					this.filters.network_id = record[dim.name].id;
					break;
				case 'tag':
					this.filters.tag_id = record[dim.name].id;
					break;
				case 'ssp':
					this.filters.ssp_id = record[dim.name].id;
					break;
				case 'placement':
					for (let k in this.filters.placement_id) {
						if (this.filters.placement_id[k].id == record[dim.name].id) {
							return false;
						}
					}
					this.filters.placement_id.push({
						id: record[dim.name].id,
						name: `[${record[dim.name].id}] ${record[dim.name].name}`,
						v: record[dim.name].id,
						t: `[${record[dim.name].id}] ${record[dim.name].name}`
					});
					break;
				case 'offer':
					for (let k in this.filters.offer_id) {
						if (this.filters.offer_id[k].id == record[dim.name].id) {
							return false;
						}
					}
					this.filters.offer_id.push({
						id: record[dim.name].id,
						name: `[${record[dim.name].id}] ${record[dim.name].name}`,
						v: record[dim.name].id,
						t: `[${record[dim.name].id}] ${record[dim.name].name}`
					});
					break;
				case 'advertiser':
					for (let k in this.filters.advertiser_id) {
						if (this.filters.advertiser_id[k].v == record[dim.name].id) {
							return false;
						}
					}
					this.filters.advertiser_id.push({
						v: record[dim.name].id,
						t: `[${record[dim.name].id}] ${record[dim.name].name}`
					});
					break;
				case 'publisher':
					for (let k in this.filters.publisher_id) {
						if (this.filters.publisher_id[k].v == record[dim.name].id) {
							return false;
						}
					}
					this.filters.publisher_id.push({
						v: record[dim.name].id,
						t: `[${record[dim.name].id}] ${record[dim.name].name}`
					});
					break;
				case 'asset':
					for (let k in this.filters.asset_id) {
						if (this.filters.asset_id[k].v == record[dim.name].id) {
							return false;
						}
					}
					this.filters.asset_id.push({
						v: record[dim.name].id,
						t: `[${record[dim.name].id}] ${record[dim.name].name}`
					});
					break;
			}
			this.$forceUpdate();
		},

		updateDates(d){
			this.filters.datePicker = d;
			// console.log('updateDates', d);
		},

		getURLFiltersParams(record, moreParams){
			let params = {
				page: this.paginate.page,
				page_size: this.paginate.limit,
				keyword: this.filters.keyword,
				country: this.filters.country,

				timezone: this.filters.datePicker.timezone,
				advertiser_id: this.filters.advertiser_id.map(r => r.v).join(','),
				publisher_id: this.filters.publisher_id.map(r => r.v).join(','),

				target_offer_id: this.filters.target_offer_id.map(r => r.v).join(','),
				offer_id: this.filters.offer_id.map(r => r.v).join(','),
				asset_id: this.filters.offer_id.map(r => r.v).join(','),
				placement_id: this.filters.placement_id.map(r => r.v).join(','),

				date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),

			};
			if (typeof (moreParams) !== 'undefined' && moreParams) {
				Object.assign(params, moreParams);
			}

			this.selectedDimensions.forEach(d => {
				params[d.field] = record[d.field];
			});
			return params;
		},
		showBackgroundReportsModal(){
			// this.$modal.show(GeneratedReportsModal, {}, {
			// 	height: '90%'
			// });
		},
		async searchPlacement(query){
			this.placementOptionsLoading = true;
			clearTimeout(this.placementOptionsTimeout);
			if (!query){
				this.placementOptionsLoading = false;
				return;
			}
			this.placementOptionsTimeout = setTimeout(async () => {
				try {
					this.placementOptions = await Vue.$dwData.placement.getOptions({keyword: query});
				} catch (e) {
					console.error(e);
				}
				this.placementOptionsLoading = false;
			}, 500);
		},

		async searchOffer(query){
			this.offerOptionsLoading = true;
			clearTimeout(this.offerOptionsTimeout);
			if (!query){
				this.offerOptionsLoading = false;
				return;
			}
			this.offerOptionsTimeout = setTimeout(async () => {
				try {
					this.offerOptions = await Vue.$dwData.offer.getOptions({keyword: query});
				} catch (e) {
					console.error(e);
				}
				this.offerOptionsLoading = false;
			}, 500);
		},

		removeMetric: function (d){
			d._selected = false;
		},
		removeDimension: function (d){
			d._selected = false;
		},
		selectOffersFilter(){
			// let selectedOffers = this.filters.offer_id;
			// this.$modal.show(SelectOffersModal,
			// 	{
			// 		selectedOffers
			// 	},
			// 	{
			// 		height: '90%',
			// 		width: '90%',
			// 		minWidth: 500
			// 		//maxWidth: '100%',
			// 	},
			// 	{
			// 		'before-close': (/*event*/) => {
			// 			Vue.set(this.filters, 'offer_id', selectedOffers);
			// 		}
			// 	}
			// );
		},
		toggleFilters: function (){
			this.filtersExpanded = !this.filtersExpanded
		},
		async loadData(doExport){
			let metrics = [];
			this.metrics.forEach(m => {
				if (m._selected) {
					metrics.push(m.name);
				}
				if (m.children) {
					m.children.forEach(cm => {
						if (cm._selected) {
							metrics.push(cm.name);
						}
					});
				}
			});

			let dimensions = this.dimensions.filter(d => d._selected).map(d => d.name);

			let params = {
				export: doExport ? doExport : 0,
				extended_params: this.filters.extended_params ? 1 : 0,
				page: this.paginate.page,
				page_size: this.paginate.limit,
				group_timeframe: this.filters.group_timeframe,
				timezone: this.filters.datePicker.timezone,
				date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
				sort_col: this.sortBy.field,
				sort_dir: this.sortBy.direction,
				groups_mode: this.filters.groups_mode,
				mode: this.filters.mode,
				filters: {
					subsource_data: this.filters.subsource_data ? 1 : 0,
					has_conversions: this.filters.has_conversions ? 1 : 0,
					network_id: this.filters.network_id ? [this.filters.network_id] : [],
					tag_id: this.filters.tag_id ? [this.filters.tag_id] : [],
					publisher_feed_id: this.filters.publisher_feed_id ? [this.filters.publisher_feed_id] : [],
					ssp_id: this.filters.ssp_id ? [this.filters.ssp_id] : [],
					advertiser_id: this.filters.advertiser_id.map(r => r.v),
					publisher_id: this.filters.publisher_id.map(r => r.v),
					target_offer_id: this.filters.target_offer_id.map(r => r.id),
					offer_id: this.filters.offer_id.map(r => r.id),
					asset_id: this.filters.asset_id.map(r => r.v),
					placement_id: this.filters.placement_id.map(r => r.v),
					os_id: this.filters.os_id.map(r => r.v),
					device_model_id: this.filters.device_model_id.map(r => r.v),
					device_vendor_id: this.filters.device_vendor_id.map(r => r.v),
					browser_id: this.filters.browser_id.map(r => r.v),
					country: this.filters.country,
				},
				metrics: metrics,
				dimensions: dimensions
			};
			if (this.busy) {
				return;
			}
			this.busy = true;

			try {
				let resp = await this.$api.post('reports/query', params);
				if (doExport === 1) {
					window.open(resp.path);
				} else if (doExport === 2) {
					this.showBackgroundReportsModal();
				} else {
					this.columns = resp.columns;
					this.columns.forEach(c => {

						Vue.set(c, 'mode', c.mode);
					});
					this.records = resp.records.slice(0, this.paginate.limit);
					this.summary = resp.summary;
					this.paginate.total = resp.total;
					this.resultMeta = {
						dimensions: resp.dimensions,
						metrics: resp.metrics
					};
					this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
				}
			} catch (e) {
				console.log(e);
			}
			this.busy = false;
		}
	},
	async mounted(){
		let vm = this;
		vm.busy = true;
		vm.$nextTick(async () => {
			let formInfo = await Vue.$api.get('reports/getFormInfo');
			let networkOptions = await Vue.$dwData.network.getOptions(true);
			let tagOptions = await Vue.$dwData.tag.getOptions(true);
			let publisherFeedOptions = await Vue.$dwData.publisherFeed.getOptions();
			let advertiserOptions = await Vue.$dwData.advertiser.getOptions(true);
			let publisherOptions = await Vue.$dwData.publisher.getOptions(true);
			// let offerOptions = await Vue.$dwData.offer.getOptions(true);
			let assetOptions = await Vue.$dwData.asset.getOptions(true);

			let selectedOfferOptions = await Vue.$dwData.offer.getSelectedOptions(this.offer_id);
			// let selectedPlacementOptions = await Vue.$dwData.placement.getSelectedOptions(this.placement_id);
			// let selectedOfferOptions = [];
			let selectedPlacementOptions = [];

			this.networkOptions = networkOptions;
			this.tagOptions = tagOptions;
			this.publisherFeedOptions = publisherFeedOptions;
			this.advertiserOptions = advertiserOptions;
			this.publisherOptions = publisherOptions;
			// this.offerOptions = offerOptions;
			this.assetOptions = assetOptions;
			this.filters.offer_id = selectedOfferOptions;
			this.filters.placement_id = selectedPlacementOptions;
			this.blockedErrors = formInfo.blockedErrors;
			this.rejectReasons = formInfo.rejectReasons;
			this.ctitSteps = formInfo.ctitSteps;
			this.dimensions = formInfo.dimensions;
			this.dimensions.forEach(d => {
				Vue.set(d, '_selected', false);
				this.dimHash[d.name] = d;
			});
			this.metricsHash = {};
			this.metrics = formInfo.metrics;
			this.metrics.forEach(d => {
				switch (d.name) {
					case 'conversions':
						d.link = 'events';
						break;
					case 'reported_conversions':
						d.link = 'events';
						d.linkParams = {reported: 1};
						break;
					case 'events':
						d.link = 'events';
						break;
					case 'reject_events':
						d.link = 'rejection-events';
						break;
					// case 'reject_events':
						// d.link = 'rejected';
						// break;
				}
				Vue.set(d, '_expanded', false);
				Vue.set(d, '_selected', false);
				if (d.children) {
					d.children.forEach(c => {
						Vue.set(c, '_selected', false);
						this.metricsHash[c.name] = c;
					});
				}
				this.metricsHash[d.name] = d;
			});
			this.presets = formInfo.presets;
			this.browserOptions = formInfo.options.browser;
			this.osOptions = formInfo.options.os;
			this.deviceVendorOptions = formInfo.options.device_vendor;
			this.deviceModelOptions = formInfo.options.device_model;

			this.setPreset(this.presets[0]);

			vm.busy = false;
			if (vm.config && vm.config.loadOnStart){
				this.loadData();
			}
		});
	}
}
</script>
